.ant-list-item {
  padding: 12px 12px !important;
}

.ant-table-cell .ant-btn.ant-btn-link {
  padding: 0 4px !important;
}

.ant-btn + .ant-btn {
  margin-left: 10px;
}
