html,
body,
#root,
#root>* {
  height: 100%;
}

body {
  margin: 0;
  font-size: 14px;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

#searchForm>.ant-row>.ant-row.ant-form-item {
  width: 100%;
  text-align: right;
  padding-right: 12px;

  .ant-col {
    flex: 1;
    max-width: 100%;
    width: 100%;

    .ant-btn:first-child {
      margin-right: 10px;
    }
  }
}

.app-loading-wrapper.ant-spin-spinning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(256, 256, 256, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}