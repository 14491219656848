.base-view {
  display: flex;
  flex-direction: column;

  .tabs-main {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .search {
    margin-bottom: 10px;
  }

  .aside-main {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;

    @media screen and (max-height: 800px) {
      overflow: auto;
    }
  }

  .table {
    flex: 1;
    overflow: hidden;

    @media screen and (max-height: 800px) {
      overflow: auto;
      min-height: 500px;
    }
  }
}